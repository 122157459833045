<template>
  <div class="category-form" style="position: realtive;">
    <loading-spinner v-if="loading" position="absolute"></loading-spinner>

    <div class="taps-container">
      <div class="m-tab-nav rounded-corners reverse">
        <div
          v-for="option in tabs"
          :key="option.title"
          class="m-tab rounded-corners"
          :class="{ selected: option.selected }"
          @click="onTabChanged(option.name)"
        >
          <span>{{ option.title }}</span>
          <font-awesome-icon :icon="option.icon" />
        </div>
      </div>
    </div>

    <keep-alive>
      <div v-if="!loading && mixin_selectedTab === 'category'">
        <p id="section-title">{{ categoryId ? "تعديل معلومات الفئة" : "إضافة فئة جديدة" }}</p>

        <inputField
          v-for="(property_, index) in categoryInputs"
          v-if="property_.optional == null"
          :data-v-step="index"
          :key="index"
          :inputType="property_.type"
          :title="property_.title"
          :categoryId="categoryId"
          :inputIsRequired="property_.isRequired"
          :inputIsInteger="property_.isInteger"
          :optionsUrl="property_.optionsUrl"
          :value="category[property_.name]"
          v-model="category[property_.name]"
        >
        </inputField>

        <optionalInput
          v-for="(property_, index) in categoryInputs"
          v-if="property_.optional"
          :key="index"
          :data-v-step="index"
          :name="property_.name"
          :enabled="!!category[property_.name]"
          @usageState="
            payload => {
              category[payload.name] = payload.state ? category[payload.name] : null;
            }
          "
          :header="property_.header"
        >
          <template v-slot:body>
            <dateInptu
              v-if="property_.type === 'date'"
              :title="property_.title"
              :value="category[property_.name]"
              v-model="category[property_.name]"
            >
            </dateInptu>

            <inputField
              v-else
              :key="index"
              :inputType="property_.type"
              :title="property_.title"
              :inputIsRequired="property_.isRequired"
              :inputIsInteger="property_.isInteger"
              :value="category[property_.name]"
              v-model="category[property_.name]"
            >
            </inputField>
          </template>
        </optionalInput>

        <div id="category-controll">
          <button class="mujeeb-button green big" @click="submit()">
            {{ categoryId ? "تعديل" : "إضافة" }}
          </button>
          <button class="mujeeb-button big" @click="$router.go(-1)">عودة</button>
        </div>
      </div>
    </keep-alive>

    <div v-if="!loading && mixin_selectedTab === 'categoryProducts'">
      <categoryItems :categoryId="categoryId"></categoryItems>
    </div>

    <div v-if="!loading && mixin_selectedTab === 'addProducts'">
      <itemsAdditionTable
        :categoryId="categoryId"
        :categoryName="category.name"
        @GO_BACK="mixin_selectedTab = 'category'"
      ></itemsAdditionTable>
    </div>

    <v-tour
      name="category_tour"
      :steps="tourSteps"
      :options="tourOptions"
      :callbacks="tourCallbacks"
    ></v-tour>
  </div>
</template>

<script>
import inputField from "../../input/GenericInputField";
import dateInptu from "../../input/DateInput";
import optionalInput from "../../input/OptionalInput";
import categoryItems from "./categoryItems.vue";
import itemsAdditionTable from "./itemsAdditionTable";
import tabsMixin from "../../../mixins/tabs";

import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("recipesConfig");

function isInteger(condition) {
  return value => {
    return condition ? /^\d+$/.test(value) : true;
  };
}

const isRequired = (condition, inputType) => value => {
  return condition ? (inputType === "tags" ? value.length !== 0 : value !== "") : true;
};

export default {
  name: "categories",
  components: {
    inputField,
    dateInptu,
    optionalInput,
    categoryItems,
    itemsAdditionTable
  },
  mixins: [tabsMixin("category", "يجب عليك إدخال معلومات الفئة أولاً")],
  data() {
    const tabs = [
      {
        title: "معلومات الفئة",
        icon: "edit",
        selected: true,
        name: "category",
        primary: true
      },
      {
        title: "منتجات الفئة",
        icon: "sort",
        selected: false,
        name: "categoryProducts"
      },
      {
        title: "إضافة منتجات",
        icon: "plus",
        selected: false,
        name: "addProducts"
      }
    ];
    this.$store.getters["recipesConfig/navigationTabs"].categoryTabs.map((tabTitle, index) => {
      tabs[index].title = tabTitle;
    });
    return {
      tabs: tabs,
      category: {
        id: ""
      },
      categoryId: null,
      loading: false,
      canEdit: false,
      previousParent: null,
      tourOptions: {
        labels: {
          buttonSkip: "إنهاء",
          buttonPrevious: "السابق",
          buttonNext: "التالي",
          buttonStop: "إنهاء"
        }
      },
      tourCallbacks: {
        onStop: this.stopShowingTour
      }
    };
  },
  methods: {
    submit() {
      if (!this.$v.$invalid) {
        this.loading = true;
        const options = {
          method: "post",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories/category`,

          responseType: "json",
          data: this.finalCategory
        };
        if (this.categoryId && this.categoryId !== "") {
          options.method = "put";
          options.data.previousParent = this.previousParent;
        }
        this.$api
          .customRequest(options)
          .then(({ data }) => {
            this.categoryId = data.id;
            this.canEdit = true;
            this.$buefy.toast.open({
              duration: 3000,
              message: "تم إضافة الفئة",
              position: "is-top",
              type: "is-success"
            });
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.$v.category.name.$invalid) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يجب إدخال اسم الفئة",
          position: "is-top",
          type: "is-danger"
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يجب تعبئة الحقول الناقصة",
          position: "is-top",
          type: "is-danger"
        });
      }
    },
    stopShowingTour() {
      this.$buefy.dialog.confirm({
        title: `إخفاء الخطوات التعليمية`,
        message: `هل تود إيقاف إظهار الخطوات التعليمية في المرات القادمة؟`,
        confirmText: "إيقاف",
        cancelText: "كلا",
        type: "is-success",
        onConfirm: () => {
          // TODO make request to add the recipe to this pages
          this.$store.dispatch("page/stopShowingTour", "category");
        }
      });
    }
  },
  computed: {
    finalCategory() {
      const finalItemObject = Object.keys(this.category).reduce((acc, property) => {
        if (typeof this.category[property] !== "undefined" && this.category[property] != null) {
          acc[property] = this.category[property];
        }
        return acc;
      }, {});
      return Object.keys(finalItemObject).reduce((acc, property) => {
        // TODO check the properties of the item's input
        switch (typeof this.category[property]) {
          case "boolean":
            if (this.category[property] != null) {
              acc[property] = this.category[property];
            }
            break;
          case "string":
            if (this.category[property] != null && this.category[property]) {
              acc[property] = this.category[property];
            }
            break;
          case "object":
            if (Array.isArray(this.category[property])) {
              if (this.category[property].length !== 0) {
                acc[property] = this.category[property];
              }
            } else {
              if (Object.keys(this.category[property]).length !== 0) {
                acc[property] = this.category[property];
              }
            }
            break;
          default:
            break;
        }
        return acc;
      }, {});
    },
    ...mapGetters({
      categoryInputs: "categoryInput"
    }),
    tourSteps() {
      return this.categoryInputs.reduce((acc, item, index) => {
        const tmp = {};
        tmp.content = item.content;
        tmp.target = `[data-v-step="${index}"]`;
        tmp.params = {
          placement: "bottom",
          enableScrolling: false
        };
        acc.push(tmp);
        return acc;
      }, []);
    }
  },
  created() {
    this.categoryId = this.$getAtPath(["params", "categoryId"], this.$route);
    const category = this.categoryInputs.reduce((acc, property) => {
      acc[property.name] = property.default ? property.default : property.type === "tags" ? [] : "";
      return acc;
    }, {});
    if (this.categoryId) {
      this.loading = true;
      this.$api
        .customRequest({
          method: "get",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories/${this.categoryId}`,

          responseType: "json"
        })
        .then(({ data }) => {
          // this.category.id = this.categoryId
          this.canEdit = true;
          this.category = Object.assign(category, data.category);
          this.previousParent = this.category.parent_category;
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء قراءة معلومات الفئة",
            position: "is-top",
            type: "is-danger"
          });
          this.$router.go(-1);
        })
        .finally(() => {
          this.loading = false;
          // TODO back to the products table
        });
    } else {
      this.category = category;
    }
  },
  mounted() {
    const canStartTour =
      this.$store.getters["page/tours"]("category") != null
        ? this.$store.getters["page/tours"]("category")
        : true;
    if (!this.categoryId && canStartTour) {
      this.$tours["category_tour"].start();
    }
  },
  validations() {
    return {
      category: this.categoryInputs.reduce((acc, property) => {
        acc[property.name] = {
          required: isRequired(property.isRequired || false, this[property.name]),
          integer: isInteger(property.isInteger || false, this[property.name])
        };
        return acc;
      }, {})
    };
  }
};
</script>

<style scoped lang="scss">
.category-form {
  margin-bottom: 100px;
  #section-title {
    direction: rtl;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0px 50px 0px;
  }
  #category-controll {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
</style>
