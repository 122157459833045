<template>
  <div class="rtl relative">
    <loading-spinner v-if="loading" position="absolute"></loading-spinner>
    <div v-else>
      <div class="m-items-table" v-if="productsArray != null && productsArray.length > 0">
        <div class="thead">
          <div class="tr">
            <div
              class="th"
              v-for="(property, index) in Store_itemsProps"
              :class="getCssClass(property.cssClass)"
              :key="index"
            >
              {{ property.title }}
            </div>
          </div>
        </div>

        <div class="tbody">
          <div class="tr" v-for="(product, index) in productsArray" :key="index">
            <div
              class="td"
              v-for="(property, index) in Store_itemsProps"
              :key="index"
              :data="property.title"
              :class="getCssClass(property.cssClass)"
            >
              <img v-if="property.name === 'image'" :src="product.image" :alt="product.image" />
              <span v-else-if="property.name === 'name'">{{ product.name }}</span>
              <b-checkbox
                v-else-if="property.name === 'checked'"
                @input="onItemSelected($event, product)"
                :value="itemIsSelected(product.id)"
              ></b-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="border: 1px dashed black; border-radius: 6px;">
        <p class="empty-table-msg">لم يتم إدخال منتجات حتى الآن</p>
      </div>

      <div class="pagination-control">
        <button
          class="mujeeb-button icon-on-left icon-on-right"
          v-if="paginationStates[paginationState].next"
          @click="requestItemsPage"
        >
          <font-awesome-icon icon="chevron-right" />التالي
        </button>
        <button
          class="mujeeb-button icon-on-right icon-on-left"
          v-if="paginationStates[paginationState].back"
          @click="requestItemsPage(false)"
        >
          السابق<font-awesome-icon icon="chevron-left" />
        </button>
      </div>

      <div
        id="change_counter"
        v-if="addesItems_ids_array.length || removedItems_ids_array.length"
        @click="showModal = true"
      >
        <!-- <span v-if="addesItems_ids_array.length" class="addedItems" :class="{'hasAddOn': removedItems_ids_array.length}">{{ addesItems_ids_array.length }}</span>
          <span v-if="removedItems_ids_array.length" class="removedItems" :class="{'hasAddOn': addesItems_ids_array.length}">{{ removedItems_ids_array.length }}</span> -->
        خيارات
      </div>

      <addRemoveModal
        v-if="showModal"
        @CLOSE_MODAL="
          () => {
            showModal = false;
            $emit('GO_BACK');
          }
        "
        @GO_BACK="$emit('GO_BACK')"
        :categoryName="categoryName"
        :categoryId="categoryId"
      ></addRemoveModal>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const recipesConfig = createNamespacedHelpers("recipesConfig");
const itemsBelongings = createNamespacedHelpers("itemsBelongings");
import PaginationMixin from "../../../mixins/pagination";
import addRemoveModal from "./../utilityComponents/addRemoveModal";
export default {
  name: "items-addition-table",
  mixins: [PaginationMixin("items", "created_at")],
  props: {
    categoryId: {
      type: String,
      required: true
    },
    categoryName: {
      type: String,
      required: true
    }
  },
  components: {
    addRemoveModal
  },
  data() {
    return {
      itemsAdded: [],
      showModal: false
    };
  },
  computed: {
    ...recipesConfig.mapGetters({
      Store_itemsProps: "itemsAdditionProps"
    }),
    ...itemsBelongings.mapGetters(["addedItems", "removedItems", "alreadyAddedItems"]),
    addesItems_ids_array() {
      return Object.keys(this.addedItems);
    },
    removedItems_ids_array() {
      return Object.keys(this.removedItems);
    }
  },
  methods: {
    itemIsSelected(id) {
      const selected = this.alreadyAddedItems[id] != null || this.addedItems[id] != null;
      return selected;
    },
    onItemSelected(newState, item) {
      if (newState) {
        if (this.alreadyAddedItems[item.id] == null) {
          this.$store.dispatch("itemsBelongings/addToAddedItems", { id: item.id, item });
        } else {
          this.$store.dispatch("itemsBelongings/removeFromRemovedItems", { id: item.id });
        }
      } else {
        if (this.alreadyAddedItems[item.id] != null) {
          this.$store.dispatch("itemsBelongings/addToRemovedItems", { id: item.id, item });
        } else {
          this.$store.dispatch("itemsBelongings/removeFromAddedItems", { id: item.id });
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch("itemsBelongings/reset");
  },
  watch: {
    productsArray(newVal, oldVal) {
      if (newVal.length) {
        for (const item of newVal) {
          if (
            item.categories_Ids &&
            item.categories &&
            item.categories_Ids.includes(this.categoryId)
          ) {
            this.$store.dispatch("itemsBelongings/addToAlreadyAddedItems", { id: item.id, item });
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
#change_counter {
  padding: 10px 20px;
  border-radius: 6px;
  background: dodgerblue;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  box-shadow: 0px 0px 5px 2px rgba(56, 119, 255, 0.644), 0 5px 15px 0 rgba(56, 119, 255, 0.644);
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #1e90ff99;
}
</style>
