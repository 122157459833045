<template>
  <div class="input-group">
    <div class="header" :class="{ 'rounded-button': !inUse }">
      <p class="header_text">{{ header }}</p>
      <span class="spacer"></span>
      <div class="header_control">
        <b-switch
          type="is-success"
          size="is-medium"
          v-model="inUse"
          @input="onInUseStateChanged"
        ></b-switch>
      </div>
    </div>

    <transition name="fade">
      <div v-if="inUse" class="body">
        <slot name="body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: "تفعيل"
    },
    name: {
      type: String,
      required: true
    },
    enabled: {
      type: Boolean,
      default: false
    },
    property: {
      type: Object
    }
  },
  data() {
    return {
      inUse: this.enabled
    };
  },
  methods: {
    onInUseStateChanged(value) {
      // if (this.property.isRequired) {
      //   this.property.isRequired = value
      // }
      this.$emit("usageState", { name: this.name, state: value });
    }
  },
  created() {
    this.inUse = this.enabled;
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/_variables.scss";
.input-group {
  border-radius: $border-radius-xs;
  box-shadow: $shadow-md;
  margin-bottom: 30px;
  .header {
    padding: $m-container_padding;
    color: white;
    font-weight: bold;
    background: #212121;
    display: flex;
    align-content: center;
    align-items: center;
    direction: rtl;
    border-top-right-radius: $border-radius-xs;
    border-top-left-radius: $border-radius-xs;
    .header_control {
      direction: ltr;
    }
    &.rounded-button {
      border-bottom-left-radius: $border-radius-xs;
      border-bottom-right-radius: $border-radius-xs;
    }
  }
  .body {
    padding: $m-container_padding;
    border-bottom-left-radius: $border-radius-xs;
    border-bottom-right-radius: $border-radius-xs;
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.25s ease-out;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }
}
</style>
