/* eslint-disable camelcase */
import bluebird from "bluebird";

function PaginationMixin(url = "items", orderBy = "created_at") {
  return {
    data() {
      const states = {
        created: {
          next: false,
          back: false
        },
        onePage: {
          next: false,
          back: false
        },
        firstPage: {
          next: true,
          back: false
        },
        lastPage: {
          next: false,
          back: true
        },
        CanFetch: {
          next: true,
          back: true
        }
      };
      return {
        productsArray: [],
        loading: false,
        paginationStates: states,
        paginationState: "created",
        direction: "next",
        fetchWhat: url,
        url,
        orderBy,
        mixin_preSelectedItems: []
      };
    },
    methods: {
      requestItemsPage(next = true) {
        const queryParams = {};
        if (next) {
          this.direction = "next";
          queryParams.nextStartAfter = this.$store.getters["itemsTable/nextStartAfter"];
        } else {
          this.direction = "back";
          queryParams.backStartAfter = this.$store.getters["itemsTable/backStartAfter"];
        }
        let fetchPromise = bluebird.resolve();
        if (this.fetchWhat === "category") {
          fetchPromise = fetchPromise.delay(500);
        }
        this.loading = true;
        return fetchPromise
          .then(() => {
            if (this.categoryId && this.url !== "items") {
              this.url = `categories/${this.categoryId}/items`;
            }
            return this.$api.customRequest({
              method: "get",
              url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/${this.url}`,
              // headers: { Authorization: `Bearer ${this.$store.getters["user/accessToken"]}` },
              responseType: "json",
              params: queryParams
            });
          })
          .then(({ data }) => {
            this.productsArray = data.items;
            if (this.productsArray != null && this.productsArray.length !== 0) {
              const orderBy = this.orderBy;
              this.$store.dispatch(
                "itemsTable/setNextStartAfter",
                this.productsArray[this.productsArray.length - 1][orderBy]
              );
              this.$store.dispatch("itemsTable/setBackStartAfter", this.productsArray[0][orderBy]);
              this.nextPaginationState(data.can_fetch, this.productsArray.length);
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      nextPaginationState(can_fetch, length) {
        switch (this.paginationState) {
          case "created":
            if (can_fetch) {
              this.paginationState = "firstPage";
            } else {
              this.paginationState = "onePage";
            }
            break;
          case "onePage":
            break;
          case "firstPage":
            if (can_fetch) {
              this.paginationState = "CanFetch";
            } else {
              this.paginationState = "lastPage";
            }
            break;
          case "lastPage":
            if (can_fetch) {
              this.paginationState = "CanFetch";
            } else {
              this.paginationState = "firstPage";
            }
            break;
          case "CanFetch":
            if (can_fetch) {
              this.paginationState = "CanFetch";
            } else {
              this.paginationState = this.direction === "next" ? "lastPage" : "firstPage";
            }
            break;
        }
      },
      getCssClass(cssClass) {
        const temp = {};
        temp[`item-width-${this.$store.getters["recipesConfig/itemsProps"].length}`] = true;
        if (cssClass) {
          temp[cssClass] = true;
        }
        return temp;
      }
    },
    created() {
      this.loading = true;
      this.$store.dispatch("itemsTable/resetPointers");
      this.requestItemsPage();
    },
    beforeDestroy() {
      this.$store.dispatch("itemsTable/resetPointers");
    }
  };
}

export default PaginationMixin;
