<template>
  <div style="direction: rtl;">
    <loading-spinner v-if="loading" position="absolute"></loading-spinner>
    <div v-else>
      <div v-if="productsArray != null && productsArray.length > 0">
        <div class="m-items-table">
          <div class="thead">
            <div class="tr">
              <div
                class="th"
                v-for="(property, index) in $store.getters['recipesConfig/categoryItemsProps']"
                :class="getCssClass(property.cssClass)"
                :key="index"
              >
                {{ property.title }}
              </div>
            </div>
          </div>

          <div class="tbody">
            <draggable :list="productsArray" :options="{ handle: '.handle' }">
              <div class="tr" v-for="(product, index) in productsArray" :key="index">
                <div
                  class="td"
                  v-for="(property, index) in $store.getters['recipesConfig/categoryItemsProps']"
                  :key="index"
                  :data="property.title"
                  :class="getCssClass(property.cssClass)"
                >
                  <img v-if="property.name === 'image'" :src="product.image" :alt="product.image" />
                  <span v-else-if="property.name === 'name'">{{ product.name }}</span>
                  <span v-else-if="property.name === 'category'">
                    <span v-for="cat in product.categories" :key="cat.id">{{ cat.name }}</span>
                  </span>
                  <span v-else-if="property.name === 'variations'">{{
                    product.variations == null || product.variations.length === 0
                      ? "لايوجد"
                      : "يوجد"
                  }}</span>
                  <template v-else>
                    <button class="m-delete" data="حذف" @click="removeItemFormCategory(product)">
                      <font-awesome-icon icon="trash-alt" />
                    </button>
                  </template>
                </div>
                <div class="handle"><font-awesome-icon icon="arrows-alt-v" /></div>
              </div>
            </draggable>
          </div>
        </div>

        <div class="submit-cancel-controll">
          <button class="mujeeb-button big" @click="goToCategories">عودة</button>
          <button class="mujeeb-button green big" @click="updateItemsOrder">تحديث</button>
        </div>
      </div>
      <div v-else style="border: 1px dashed black; border-radius: 6px;">
        <p class="empty-table-msg">لم يتم إدخال منتجات حتى الآن</p>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import PaginationMixin from "../../../mixins/pagination";
export default {
  name: "categoryItems",
  props: {
    categoryId: String
  },
  components: {
    draggable
  },
  mixins: [PaginationMixin("category", "index")],
  methods: {
    updateItemsOrder() {
      this.loading = true;
      const itemsNewOrder = this.productsArray.reduce((acc, item, index) => {
        acc.push(item.id);
        return acc;
      }, []);
      this.$api
        .customRequest({
          method: "put",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories/${this.categoryId}/items`,

          responseType: "json",
          data: {
            items: itemsNewOrder
          }
        })
        .then(({ data }) => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "تم تحديث الترتيب",
            position: "is-top",
            type: "is-success"
          });
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء تحديث الترتيب",
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItemFormCategory(item) {
      // TODO show loading screen OR toast
      // TODO remove the product from the array if SUCCEEDED
      this.loading = true;
      this.$api
        .customRequest({
          method: "delete",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories/${this.categoryId}/items/${item.id}`,

          responseType: "json"
        })
        .then(({ data }) => {
          const itemIndex = this.productsArray.findIndex(itemObject => itemObject.id === item.id);
          this.productsArray.splice(itemIndex, 1);
          this.$buefy.toast.open({
            duration: 3000,
            message: "تم حذف الفئة",
            position: "is-top",
            type: "is-success"
          });
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء الحذف",
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToCategories() {
      this.$router.push({ name: "chatbot.categories" });
    }
  }
};
</script>

<style scoped lang="scss">
.submit-cancel-controll {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

// .m-items-table {
//   .tbody {
//     .tr {
//       &[draggable=true] {
//         box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
//         border: 2px dashed rgb(30, 143, 255);
//         border-top: 3px solid rgb(30, 143, 255);
//       }
//     }
//   }
// }
.m-items-table .thead .tr .th.control-container {
  text-align: right;
  flex: 0 0 110px;
}

@media screen and (max-width: 850px) {
  .m-items-table .tbody .tr .td.image img {
    height: 80px;
  }
}
</style>
