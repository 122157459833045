<template>
  <div class="date-input-wraper">
    <div style="direction: rtl;">
      <label class="label">{{ title }}:</label>
      <div v-if="typeof tooltips !== 'undefined'" class="m-tooltip">
        <img
          v-if="tooltips.text"
          class="big-tabs__help-icon"
          src="../../assets/svg/icon.svg"
          alt="Info icon"
          width="15"
          height="15"
        />
        <span class="tooltip-content"
          >{{ tooltips.text }} <a :href="tooltips.link">{{ tooltips.linkText }}</a></span
        >
      </div>
    </div>
    <v-date-picker
      mode="range"
      :select-attribute="select_attribute"
      :drag-attribute="drag_attribute"
      :theme-styles="themeStyles"
      is-inline
      is-expanded
      :popover-expanded="true"
      :popover-visibility="pickerVisibility"
      @input="valueChanged"
      :input-props="{
        class: 'input',
        placeholder: 'اختر تاريخاً لإظهار المنتج خلاله',
        readonly: true
      }"
      v-model="selectedDate"
    >
    </v-date-picker>
  </div>
</template>

<script>
export default {
  name: "date-input",
  props: {
    inputType: {
      type: String,
      default: "text"
    },
    title: {
      type: String,
      required: true
    },
    tooltips: {
      type: Object,
      default: function() {
        return {
          text: "",
          link: "",
          linkText: ""
        };
      }
    },
    value: {
      type: [String, Array, Number, Object],
      default: function() {
        return null;
      }
    }
  },
  data() {
    const hSpacing = "15px";
    return {
      selectedDate: null,
      pickerVisibility: "visible",
      select_attribute: {
        highlight: {
          backgroundColor: "#007afe"
        }
      },
      drag_attribute: {
        highlight: {
          backgroundColor: "#007afe"
        }
      },
      themeStyles: {
        wrapper: {
          background: "white"
        },
        header: {
          padding: `20px ${hSpacing}`
        },
        headerHorizontalDivider: {
          borderTop: "solid rgba(57, 57, 57, 0.2) 1px",
          width: "80%"
        },
        weekdays: {
          color: "black", // New color
          fontWeight: "700", // And bolder font weight
          padding: `20px ${hSpacing} 5px ${hSpacing}`
        },
        weeks: {
          padding: `0 ${hSpacing} ${hSpacing} ${hSpacing}`
        },
        dayContent: {
          color: "black",
          fontWeight: "600"
        }
      }
    };
  },
  methods: {
    valueChanged(newVal) {
      this.$emit("input", newVal);
    }
  },
  created() {
    this.selectedDate = this.value || null;
  }
};
</script>

<style></style>
