<template>
  <div class="m-modal-container">
    <div class="m-list-modal">
      <div class="m-list-modal__tabs">
        <div
          class="m-list-modal__tab"
          :class="{ selected_tab: selectedTab === 'added' }"
          @click="useAddedItemsList"
        >
          العناصر المضافة
        </div>
        <div
          class="m-list-modal__tab"
          :class="{ selected_tab: selectedTab === 'removed' }"
          @click="useRemovedItemsList"
        >
          العناصر المحذوفة
        </div>
      </div>

      <div class="m-list-modal__content">
        <ul class="m-list-modal__body">
          <li v-for="(item, index) in source" :key="index">
            <span>{{ item }}</span>
            <button class="mujeeb-button" style="margin: 0px;">
              <font-awesome-icon icon="trash-alt" />
            </button>
          </li>
          <li v-if="source.length === 0" class="empty-list">
            لا يوجد عناصر مختارة
          </li>
        </ul>

        <div class="m-list-modal__control">
          <button class="mujeeb-button" @click="$emit('CLOSE_MODAL')">إغلاق</button>
          <button class="mujeeb-button green-border" @click="submit">تحديث</button>
          <button class="mujeeb-button red-border" @click="discard">إلغاء</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../utils/EventBus";
import { createNamespacedHelpers } from "vuex";
const itemsBelongings = createNamespacedHelpers("itemsBelongings");
export default {
  props: {
    categoryId: {
      type: String,
      required: true
    },
    categoryName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      source: [],
      selectedTab: "added"
    };
  },
  computed: {
    ...itemsBelongings.mapGetters(["addedItems", "removedItems", "alreadyAddedItems"]),
    addedItemsArray() {
      return Object.keys(this.addedItems).map(key => this.addedItems[key].name);
    },
    removedItemsArray() {
      return Object.keys(this.removedItems).map(key => this.removedItems[key].name);
    }
  },
  methods: {
    useAddedItemsList() {
      this.selectedTab = "added";
      this.source = this.addedItemsArray;
    },
    useRemovedItemsList() {
      this.selectedTab = "removed";
      this.source = this.removedItemsArray;
    },
    submit() {
      this.$api
        .customRequest({
          method: "put",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories/${this.categoryId}/update/items`,

          responseType: "json",
          data: {
            categoryName: this.categoryName,
            removedItems: Object.keys(this.removedItems),
            addedItems: Object.keys(this.addedItems)
          }
        })
        .then(() => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "تم تحديث الفئة بالمنتجات",
            position: "is-top",
            type: "is-success"
          });
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء القراءة",
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.$emit("GO_BACK");
        });
    },
    discard() {
      this.$store.dispatch("itemsBelongings/reset");
      this.$emit("CLOSE_MODAL");
    }
  },
  beforeDestroy() {
    EventBus.$emit("MODAL_CLOSED");
  },
  created() {
    this.source = this.addedItemsArray.length ? this.addedItemsArray : this.removedItemsArray;
    this.selectedTab = this.addedItemsArray.length ? "added" : "removed";
    EventBus.$emit("MODAL_OPENED");
  }
};
</script>

<style lang="scss" scoped>
.m-modal-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.589);
  .m-list-modal {
    background: white;
    border-radius: 6px;
    width: 300px;
    .m-list-modal__tabs {
      display: flex;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      height: 60px;
      cursor: pointer;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      .m-list-modal__tab {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        color: gray;
        background: rgb(196, 196, 196);
        font-weight: bold;
        &:first-child {
          border-top-right-radius: 6px;
        }
        &:last-child {
          border-top-left-radius: 6px;
        }
        &.selected_tab {
          background: white;
          color: dodgerblue;
        }
      }
    }
    .m-list-modal__content {
      padding: 10px;
      .m-list-modal__body {
        max-height: 200px;
        overflow-y: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 15px;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.04);
        li {
          display: flex;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          margin-bottom: 10px;
          &:last-child {
            margin: 0px;
          }
          &.empty-list {
            justify-content: center;
          }
          .mujeeb-button {
            padding: 5px;
            color: #ff6c8a;
            i {
              margin: 0px;
              padding: 0px;
              font-size: 20px;
            }
          }
        }
      }
      .m-list-modal__control {
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
      }
    }
  }
}
</style>
