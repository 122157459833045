/* eslint-disable camelcase */
function tabsMixins(selected, errorMsg) {
  return {
    data() {
      return {
        mixin_selectedTab: selected,
        errorMsg
      };
    },
    methods: {
      onTabChanged(name) {
        // const selectedTab = this.tabs.filter(tab => tab.name === name)[0]
        if (this.canEdit) {
          this.chaneSelectedTab(name);
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: errorMsg,
            position: "is-top",
            type: "is-warning"
          });
        }
      },
      chaneSelectedTab(name) {
        this.tabs.map(option => {
          if (option.name === name) {
            option.selected = true;
            this.mixin_selectedTab = option.name;
          } else {
            option.selected = false;
          }
        });
      }
    }
  };
}

export default tabsMixins;
